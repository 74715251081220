import { useRouter } from "next/router";
import { useEffect } from "react"

export const useOAuthFlow = () => {
  const { query } = useRouter()
  const isOAuthCallback = Boolean(query?.code)
  useEffect(() => {
    if (isOAuthCallback) {
      window.location.replace(`/api/auth/oauth2/callback${window.location.search}`)
    }
  }, [isOAuthCallback])

  return isOAuthCallback
}
