import { BlitzPage } from "@blitzjs/next"
import { useRouter } from "next/router"
import { serverSideTranslations } from "next-i18next/serverSideTranslations"
import Layout from "src/app/core/layouts/Layout"
import { useOAuthFlow } from "src/app/auth/hooks/useOAuthFlow"
import MyLoginForm from "src/app/auth/components/MyLoginForm"

const LoginPage: BlitzPage = () => {
  const router = useRouter()
  const isOAuthCallback = useOAuthFlow()

  if (isOAuthCallback) return null

  return (
    <MyLoginForm
      // onSuccess={(_user) => {
      //   const next = router.query.next ? decodeURIComponent(router.query.next as string) : "/"
      //   void router.push(next)
      // }}
      loginType={"emailCode"}
    />
  )
}

LoginPage.redirectAuthenticatedTo = (context) => {
  if (context.session.schoolSlug) return `/${context.session.schoolSlug}`
  return false
}
LoginPage.getLayout = (page) => <Layout title="Log In">{page}</Layout>

export async function getStaticProps({ locale }) {
  return {
    props: {
      ...(await serverSideTranslations(locale, ["common"])),
      // Will be passed to the page component as props
    },
  }
}

export default LoginPage
